import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
// import Brightness7 from '@material-ui/icons/Brightness7';
import MenuIcon from '@material-ui/icons/Menu';
// import NightsStay from '@material-ui/icons/NightsStay';
import { FiWifiOff, FiWifi } from 'react-icons/fi';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import { useTheme } from '../../hooks/theme';
import { useAuth } from '../../hooks/auth';
import { usePermission } from '../../hooks/permission';
import { useToast } from '../../hooks/toast';
import { useStyles } from './styles';
import { useNetwork } from '../../hooks/network';
import api from '../../services/api';
import { encrypt, decrypt } from '../../services/crypt';
import Controls from '../controls/Controls.js';

interface ISafra {
  id: string;
  safra: number;
  ativa: boolean;
  exibir: boolean;
}

interface ISafraSelect {
  id: string;
  title: string;
}

interface ToggleProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function Header({ handleDrawerToggle }: ToggleProps): any {
  const classes = useStyles();
  const { user, signOut } = useAuth();
  const { isOnline } = useNetwork();
  const { addToast } = useToast();
  const { setSafra, idSafra, setIdSafra, moduloName } = usePermission();
  const [safras, setSafras] = useState<ISafraSelect[]>([]);
  const [loadingSafras, setLoadingSafras] = useState(true);
  const [idSafraAnterior, setIdSafraAnterior] = useState<string>('');
  // const { theme: currentTheme, toogleTheme } = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  useEffect(() => {
    setLoadingSafras(true);
    api
      .get(`/safras/`, {
        params: {
          filter: encrypt(
            JSON.stringify({
              exibir: true,
            }),
          ),
        },
        headers: {
          activity: encrypt('Listar Safras'),
        },
      })
      .then(response => {
        const content = decrypt<ISafra[]>(response.data);
        const listaSafras = content.map(e => ({
          id: e.id,
          title: `${e.safra.toString().substring(0, 4)}/${e.safra
            .toString()
            .substring(4)}`,
        }));
        setSafras(listaSafras);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao listar as Safras!',
          description: 'Ocorreu um erro ao tentar listar as Safras.',
        });
      });
    setLoadingSafras(false);
  }, [addToast]);

  const handleSafraChange = (e: any): any => {
    const { target } = e;
    const id_safra = target.value;
    if (id_safra !== '' && id_safra !== idSafraAnterior) {
      setIdSafra(id_safra);
      setIdSafraAnterior(id_safra);
      const safraSelecionada = safras.find(s => s.id === id_safra);
      if (safraSelecionada && safraSelecionada.title) {
        setSafra(Number(safraSelecionada.title.replace('/', '')));
      }
    } else if (id_safra === '' && idSafra === '') {
      setIdSafra(undefined);
      setIdSafraAnterior('');
      setSafra(undefined);
    }
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          className={classes.navIconHide}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.brand}>
          Sistema ATR
        </Typography>
        <div className={classes.pageTitle}>
          <Typography variant="subtitle1" component="div" noWrap>
            <Link
              to="/perfil"
              style={{ textDecoration: 'none', color: '#ffffff' }}
            >
              {user.nome}
            </Link>
          </Typography>
          <Typography
            className={classes.headerTitle}
            variant="subtitle2"
            component="div"
            noWrap
          >
            Seja bem vindo
          </Typography>
        </div>
        <div className={classes.safraSelect}>
          <p style={{ fontSize: '10px' }}>Safra selecionada</p>
          <Controls.Select
            label="Safra"
            noLabel
            colorLabel="#ffffff"
            onChange={handleSafraChange}
            options={safras}
            value={idSafra}
            disabled={loadingSafras}
            variant="standard"
            style={{ width: '130px', color: '#ffffff' }}
          />
        </div>
        <Typography
          variant="h6"
          color="inherit"
          noWrap={isMobile}
          className={classes.title}
        >
          {moduloName !== '' ? `${moduloName}` : ''}
        </Typography>
        {/* <IconButton
          color="inherit"
          aria-label="theme changer"
          className={classes.themeChanger}
          onClick={toogleTheme}
        >
          {currentTheme === 'light' ? <Brightness7 /> : <NightsStay />}
        </IconButton> */}
        <IconButton id="network_status" color="inherit" aria-label="online">
          {isOnline ? <FiWifi /> : <FiWifiOff />}
        </IconButton>
        {!isMobile && (
          <IconButton color="inherit" aria-label="logout" onClick={signOut}>
            <PowerSettingsNewIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}
