import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: theme.zIndex.drawer + 1,
    // zIndex: 1500,
    position: 'fixed',
  },
  navIconHide: {
    // margin: -15,
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      // display: 'none',
      marginRight: theme.spacing(2),
    },
  },
  brand: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      width: '150px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '150px',
    },
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      opacity: 0,
    },
  },
  themeChanger: {
    color: theme.palette.tertiary.main,
  },
  imageHeader: {
    marginRight: '40px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '10px',
      marginLeft: '15px',
    },
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: '5px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  linkUser: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#999',
    },
  },
  pageTitle: {
    paddingLeft: theme.spacing(2),
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  headerTitle: {
    paddingRight: theme.spacing(2),
  },
  moduleTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  safraSelect: {
    marginLeft: '10px',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: '40px',
    },
  },
}));
