import { makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;
export const useStyles = makeStyles((theme: Theme) => ({
  drawerHeader: {
    ...theme.mixins.toolbar,
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  imageHeader: {
    marginLeft: '5px',
    borderRadius: '10px',
    border: '1px solid #111',
  },
  drawerPaper: {
    width: 330,
    color: '#000',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('xs')]: {
      width: drawerWidth,
      position: 'relative',
      height: '100%',
    },
  },
  userHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '10px',
    // marginRight: '10px',
  },
  button: {
    color: theme.palette.text.primary,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  mobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaperOld: {
    width: drawerWidth,
    background: '#f1eadd',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  headerTitle: {
    paddingRight: theme.spacing(2),
  },
  drawerTitle: {
    paddingLeft: theme.spacing(2),
    display: 'block',
  },
}));
