import React, { createContext, useCallback, useContext, useState } from 'react';
import socketio from 'socket.io-client';
import { useNetwork } from './network';

interface ISocketContextData {
  socket?: SocketIOClient.Socket;
  conectarSocket(userId: string): void;
  reconectarSocket(userId: string): void;
  desconectarSocket(): void;
}

const SocketContext = createContext<ISocketContextData>(
  {} as ISocketContextData,
);

let socketClient: SocketIOClient.Socket;

const SocketProvider: React.FC = ({ children }) => {
  const { setIsOnline } = useNetwork();
  const [socketIO, setSocketIO] = useState<SocketIOClient.Socket>();

  const reconectarSocket = useCallback(
    (userId: string) => {
      if (userId && !socketClient && !socketIO) {
        try {
          socketClient = socketio.connect(
            `${process.env.REACT_APP_SOCKET_API_URL}`,
          );
          // if (process.env.REACT_APP_NODE_ENV === 'development') {
          //   socketClient = socketio.connect(
          //     `${process.env.REACT_APP_SOCKET_API_URL}`,
          //   );
          // } else {
          //   socketClient = socketio.connect(
          //     `${process.env.REACT_APP_SOCKET_API_URL}`,
          //     { path: '/ws/' },
          //   );
          // }

          socketClient.on('connect_error', (err: any) => {
            setIsOnline(false);
          });

          socketClient.on('connect', () => {
            setSocketIO(socketClient);
            console.log(
              '[IO] Connect API => A new connection has been established',
            );
            socketClient.emit('usuario', userId);
            setIsOnline(true);
          });
        } catch {
          setIsOnline(false);
        }
      }
    },
    [setIsOnline, socketIO],
  );

  const conectarSocket = useCallback(
    (userId: string) => {
      if (userId) {
        try {
          socketClient = socketio.connect(
            `${process.env.REACT_APP_SOCKET_API_URL}`,
          );
          // if (process.env.REACT_APP_NODE_ENV === 'development') {
          //   socketClient = socketio.connect(
          //     `${process.env.REACT_APP_SOCKET_API_URL}`,
          //   );
          // } else {
          //   socketClient = socketio.connect(
          //     `${process.env.REACT_APP_SOCKET_API_URL}`,
          //     { path: '/ws/' },
          //   );
          // }

          socketClient.on('connect_error', (err: any) => {
            setIsOnline(false);
          });

          socketClient.on('connect', () => {
            setSocketIO(socketClient);
            console.log(
              '[IO] Connect API => A new connection has been established',
            );
            socketClient.emit('usuario', userId);
            setIsOnline(true);
          });
        } catch {
          setIsOnline(false);
        }
      }
    },
    [setIsOnline],
  );

  const desconectarSocket = useCallback(() => {
    if (socketIO) {
      socketIO.emit('kick', socketIO.id);
      setSocketIO(undefined);
      socketClient.close();
    }
  }, [socketIO]);

  return (
    <SocketContext.Provider
      value={{
        socket: socketIO,
        conectarSocket,
        reconectarSocket,
        desconectarSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): ISocketContextData {
  const context = useContext(SocketContext);

  return context;
}

export { SocketProvider, useSocket };
