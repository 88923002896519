import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ProtectedRoute from './Route';

import SignIn from '../pages/SignIn';
import EsqueciSenha from '../pages/Usuario/EsqueciSenha';
import ResetarSenha from '../pages/Usuario/ResetarSenha';

const Industria = lazy(() => import('../pages/Administracao/Industria'));
const Associacao = lazy(() => import('../pages/Administracao/Associacao'));
const Safra = lazy(() => import('../pages/Administracao/Safra'));
const Categoria = lazy(() => import('../pages/Administracao/Categoria'));
const OcorrenciaAnalise = lazy(
  () => import('../pages/Administracao/OcorrenciaAnalise'),
);
const Clarificante = lazy(() => import('../pages/Administracao/Clarificante'));
const Formula = lazy(() => import('../pages/Administracao/Formula'));
const RegiaoOrplana = lazy(
  () => import('../pages/Administracao/RegiaoOrplana'),
);
const RegiaoUnica = lazy(() => import('../pages/Administracao/RegiaoUnica'));
const Token = lazy(() => import('../pages/UnidadeIndustrial/Token'));
const SafraIndustria = lazy(() => import('../pages/UnidadeIndustrial/Safra'));
const AtualizacaoIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Atualizacao'),
);
const AtualizacaoIndustriaAssociacao = lazy(
  () => import('../pages/Associacao/Atualizacao'),
);
const AtualizacaoIndustriaOrplana = lazy(
  () => import('../pages/Orplana/Atualizacao'),
);
const AtualizacaoIndustriaUnica = lazy(
  () => import('../pages/Unica/Atualizacao'),
);
const Menu = lazy(() => import('../pages/Administracao/Menu'));
const SubMenu = lazy(() => import('../pages/Administracao/SubMenu'));
const GrupoUsuario = lazy(() => import('../pages/Administracao/GrupoUsuario'));
const GrupoPermissao = lazy(
  () => import('../pages/Administracao/GrupoPermissao'),
);
const Usuario = lazy(() => import('../pages/Administracao/Usuario'));
const EquipamentoNIRIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/EquipamentoNIR'),
);
const ComparativoNIRIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/ComparativoNIR'),
);
const ComparativoNIRAssociacao = lazy(
  () => import('../pages/Associacao/ComparativoNIR'),
);
const IndustriaLimites = lazy(
  () => import('../pages/UnidadeIndustrial/Limites'),
);
const RelatorioViagemAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Viagem'),
);
const RelatorioDiarioAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Diarios/Detalhado'),
);
const RelatorioQuinzenalSistemaatrAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/SistemaATR/Quinzenal'),
);
const RelatorioQuinzenalSistemaatrFatorQualidadeAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/SistemaATR/FatorQualidade'),
);
const RelatorioQuinzenalSistemaatrQuqAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/SistemaATR/Quq'),
);
const RelatorioQuinzenalSistemaatrATRuqAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/SistemaATR/ATRuq'),
);
const RelatorioQuinzenalAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Quinzenais/Quinzenal'),
);
const RelatorioQuinzenalFatorQualidadeAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Quinzenais/FatorQualidade'),
);
const RelatorioQuinzenalGeralAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Quinzenais/Geral'),
);
const RelatorioQuinzenalGraficoAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Quinzenais/Grafico'),
);
const RelatorioProdutorPagamentoAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Produtores/Pagamento'),
);
const ResumoIndustriaAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Resumos/UnidadeIndustrial'),
);
const ResumoAcumuladoAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Resumos/Acumulado'),
);
const ResumoTabelaEntregaAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Resumos/TabelaEntrega'),
);
const ResumoTabelaEntregaIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Resumos/TabelaEntrega'),
);
const AvaliacaoAmostragemDiaAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Avaliacoes/AmostragemDia'),
);
const AvaliacaoAmostragemSafraAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Avaliacoes/AmostragemSafra'),
);
const AvaliacaoClarificanteIndustriaAssociacao = lazy(
  () =>
    import('../pages/Associacao/Relatorios/Avaliacoes/ClarificanteIndustria'),
);
const AvaliacaoAmostragemIntervaloAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Avaliacoes/AmostragemIntervalo'),
);
const AvaliacaoAmostragemIntervaloCargaAssociacao = lazy(
  () =>
    import(
      '../pages/Associacao/Relatorios/Avaliacoes/AmostragemIntervaloCarga'
    ),
);
const EstratificacaoCanaAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Estratificacoes/Cana'),
);
const EstratificacaoATRAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Estratificacoes/ATR'),
);
const EstratificacaoFatorQualidadeAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Estratificacoes/FatorQualidade'),
);
const RecolhimentoTaxaAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Mensais/RecolhimentoTaxa'),
);
const AssociadoAssociacao = lazy(
  () => import('../pages/Associacao/Relatorios/Mensais/Associados'),
);
const RelatorioViagemIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Viagem'),
);
const RelatorioDiarioIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Diarios/Detalhado'),
);
const RelatorioDiarioGeralIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Diarios/Geral'),
);
const RelatorioDiarioTipoIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Diarios/Tipos'),
);
const RelatorioDiarioEntregaIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Diarios/Entrega'),
);
const RelatorioQuinzenalSistemaatrIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/SistemaATR/Quinzenal'),
);
const RelatorioQuinzenalSistemaatrQuqIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/SistemaATR/Quq'),
);
const RelatorioQuinzenalSistemaatrATRuqIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/SistemaATR/ATRuq'),
);
const RelatorioQuinzenalIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Quinzenais/Quinzenal'),
);
const RelatorioQuinzenalFatorQualidadeIndustria = lazy(
  () =>
    import('../pages/UnidadeIndustrial/Relatorios/Quinzenais/FatorQualidade'),
);
const RelatorioQuinzenalGraficoIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Relatorios/Quinzenais/Grafico'),
);

const Profile = lazy(() => import('../pages/Profile'));
const Home = lazy(() => import('../pages/Home'));
const DashboardIndustria = lazy(
  () => import('../pages/UnidadeIndustrial/Dashboard/UnidadeIndustrial'),
);
const DashboardAssociacao = lazy(
  () => import('../pages/Associacao/Dashboard/UnidadesIndustriais'),
);
const DashboardAdministracao = lazy(() => import('../pages/Dashboard'));

const DashboardOrplana = lazy(
  () => import('../pages/Orplana/Dashboard/Principal'),
);

const DashboardOrplanaUI = lazy(
  () => import('../pages/Orplana/Dashboard/UnidadesIndustriais'),
);

const DashboardUnica = lazy(() => import('../pages/Unica/Dashboard/Principal'));

const DashboardUnicaUI = lazy(
  () => import('../pages/Unica/Dashboard/UnidadesIndustriais'),
);

const RelatorioQuinzenalOrplanaAssociacaoRelatq2 = lazy(
  () => import('../pages/Orplana/Relatorios/Quinzenais/Associacao-relatq2'),
);

const RelatorioQuinzenalOrplanaEstadoRelatq5 = lazy(
  () => import('../pages/Orplana/Relatorios/Quinzenais/Estado-relatq5'),
);

const RelatorioQuinzenalOrplanaEstadoRelatq50 = lazy(
  () => import('../pages/Orplana/Relatorios/Quinzenais/Estado-relatq50'),
);

const RelatorioQuinzenalOrplanaIndustriaRelatq21 = lazy(
  () => import('../pages/Orplana/Relatorios/Quinzenais/Industria-relatq21'),
);

const RelatorioQuinzenalOrplanaIndustriaRelatq28 = lazy(
  () => import('../pages/Orplana/Relatorios/Quinzenais/Industria-relatq28'),
);

const RelatorioAcumuladoOrplanaAssociacaoRelata2 = lazy(
  () => import('../pages/Orplana/Relatorios/Acumulados/Associacao-relata2'),
);

const RelatorioAcumuladoOrplanaRegiaoRelata42 = lazy(
  () => import('../pages/Orplana/Relatorios/Acumulados/Regiao-relata42'),
);

const RelatorioAcumuladoOrplanaEstadoRelata46 = lazy(
  () => import('../pages/Orplana/Relatorios/Acumulados/Estado-relata46'),
);

const RelatorioEstratificacaoOrplanaRelata44 = lazy(
  () => import('../pages/Orplana/Relatorios/Estratificacoes/Cana-relata44'),
);

const RelatorioSafraOrplanaAtualizacao = lazy(
  () => import('../pages/Orplana/Relatorios/Safra/Atualizacao'),
);

const RelatorioQuinzenalUnicaEstadoRelatq5 = lazy(
  () => import('../pages/Unica/Relatorios/Quinzenais/Estado-relatq5'),
);

const RelatorioQuinzenalUnicaEstadoRelatq50 = lazy(
  () => import('../pages/Unica/Relatorios/Quinzenais/Estado-relatq50'),
);

const RelatorioQuinzenalUnicaDetalhado = lazy(
  () => import('../pages/Unica/Relatorios/Quinzenais/Detalhado'),
);

const RelatorioMensalUnicaDetalhado = lazy(
  () => import('../pages/Unica/Relatorios/Mensais/Detalhado'),
);

const RelatorioSemanalUnicaDetalhado = lazy(
  () => import('../pages/Unica/Relatorios/Semanais/Detalhado'),
);

const ExportacaoDiarioAssociacao = lazy(
  () => import('../pages/Associacao/Exportacoes/Diario'),
);

const ExportacaoResumoDiarioAssociacao = lazy(
  () => import('../pages/Associacao/Exportacoes/ResumoDiario'),
);

const ExportacaoQuinzenalAssociacao = lazy(
  () => import('../pages/Associacao/Exportacoes/Quinzenal'),
);

const ExportacaoMensalAssociacao = lazy(
  () => import('../pages/Associacao/Exportacoes/Mensal'),
);

const ExportacaoFatorQualidadeAssociacao = lazy(
  () => import('../pages/Associacao/Exportacoes/FatorQualidade'),
);

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/index.html" exact component={SignIn} />
    <Route path="/signin" component={SignIn} />
    <Route path="/esqueci-senha" component={EsqueciSenha} />
    <Route path="/resetar-senha" component={ResetarSenha} />
    <ProtectedRoute
      path="/industria"
      component={Industria}
      isPrivate
      s="9da47b0d-9f98-4e29-af55-5ee44bb335ff"
    />
    <ProtectedRoute
      path="/associacao"
      component={Associacao}
      isPrivate
      s="14007dad-6644-479f-9a58-a0034d4918f5"
    />
    <ProtectedRoute
      path="/safra"
      component={Safra}
      isPrivate
      s="7ccd0c11-a3b6-4f3c-a5ba-f586d201d36c"
    />
    <ProtectedRoute
      path="/categoria"
      component={Categoria}
      isPrivate
      s="a69d16b8-2def-4b38-bb35-ff868582a0ac"
    />
    <ProtectedRoute
      path="/ocorrencia-analise"
      component={OcorrenciaAnalise}
      isPrivate
      s="063e0ecb-69b5-4327-9fbb-545010cd2828"
    />
    <ProtectedRoute
      path="/clarificante"
      component={Clarificante}
      isPrivate
      s="b3b905ce-aa5e-4ce1-9357-366d961dbeee"
    />
    <ProtectedRoute
      path="/formula"
      component={Formula}
      isPrivate
      s="bcd8f870-fc75-43c7-8c79-a11b20cd93c6"
    />
    <ProtectedRoute
      path="/regiao-orplana"
      component={RegiaoOrplana}
      isPrivate
      s="603ca3bc-e21e-4f0a-8dc7-cf2afa901c12"
    />
    <ProtectedRoute
      path="/regiao-unica"
      component={RegiaoUnica}
      isPrivate
      s="45a9ad81-04cd-4e0b-82d8-b811467a8c1d"
    />
    <ProtectedRoute
      path="/industria-token"
      component={Token}
      isPrivate
      s="10bfd3be-ec3a-4a5a-9e32-17ba8020641e"
    />
    <ProtectedRoute
      path="/safra-industria"
      component={SafraIndustria}
      isPrivate
      s="9b3ce0d4-ce03-4b65-982f-1731b2a9bb52"
    />
    <ProtectedRoute
      path="/atualizacao-industria"
      component={AtualizacaoIndustria}
      isPrivate
      s="718009ce-9fc7-461c-8261-cedf693cb83c"
    />
    <ProtectedRoute
      path="/atualizacao-industria-associacao"
      component={AtualizacaoIndustriaAssociacao}
      isPrivate
      s="8b3a093a-9d8f-40cf-bf9c-a0480d6c5258"
    />
    <ProtectedRoute
      path="/atualizacao-industria-orplana"
      component={AtualizacaoIndustriaOrplana}
      isPrivate
      s="31940adf-6f8c-4e68-b161-ed11448b4ecb"
    />
    <ProtectedRoute
      path="/atualizacao-industria-unica"
      component={AtualizacaoIndustriaUnica}
      isPrivate
      s="3c299a9a-8293-483c-82ba-9c2e1063e441"
    />
    <ProtectedRoute
      path="/menu"
      component={Menu}
      isPrivate
      s="71111a40-72d7-44a8-a1b9-f4500fd6bf8d"
    />
    <ProtectedRoute
      path="/sub-menu"
      component={SubMenu}
      isPrivate
      s="bc66dcd1-2e9c-4327-b178-aeaf8eddb788"
    />
    <ProtectedRoute
      path="/grupo-usuario"
      component={GrupoUsuario}
      isPrivate
      s="d9653aef-4145-440d-ac97-5e3581887bfe"
    />
    <ProtectedRoute
      path="/equipamento-nir"
      component={EquipamentoNIRIndustria}
      isPrivate
      s="bafff43b-9ac7-4126-9cea-7d665c7e73d9"
    />
    <ProtectedRoute
      path="/comparativo-nir-industria"
      component={ComparativoNIRIndustria}
      isPrivate
      s="5e7983ef-1e41-4d44-a7e5-78b65aefdd4e"
    />
    <ProtectedRoute
      path="/comparativo-nir-associacao"
      component={ComparativoNIRAssociacao}
      isPrivate
      s="c8dea7d3-eae9-428c-adcd-1a5e87c3cda3"
    />
    <ProtectedRoute
      path="/exportacao-associacao-diario"
      component={ExportacaoDiarioAssociacao}
      isPrivate
      s="62a6c29a-a86f-4972-86eb-f8a3ae1a93a4"
    />
    <ProtectedRoute
      path="/exportacao-associacao-resumo-diario"
      component={ExportacaoResumoDiarioAssociacao}
      isPrivate
      s="4f75074f-6a9f-4b17-9f2a-275e1081de54"
    />
    <ProtectedRoute
      path="/exportacao-associacao-quinzenal"
      component={ExportacaoQuinzenalAssociacao}
      isPrivate
      s="406c82e3-85c1-4034-a6f9-36944f60eb53"
    />
    <ProtectedRoute
      path="/exportacao-associacao-mensal"
      component={ExportacaoMensalAssociacao}
      isPrivate
      s="905b2d7c-a392-499f-b916-abefa8058533"
    />
    <ProtectedRoute
      path="/exportacao-associacao-fq"
      component={ExportacaoFatorQualidadeAssociacao}
      isPrivate
      s="2726530a-af2e-4738-b2ae-76534d26548a"
    />

    <ProtectedRoute
      path="/relatorio-associacao-viagem"
      component={RelatorioViagemAssociacao}
      isPrivate
      s="ebf7b8b5-d26b-46a0-a842-b69734675a71"
    />
    <ProtectedRoute
      path="/relatorio-associacao-diario"
      component={RelatorioDiarioAssociacao}
      isPrivate
      s="618d2d38-85de-4326-9302-5a30a863c673"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal"
      component={RelatorioQuinzenalAssociacao}
      isPrivate
      s="98a3d42f-9d04-4af5-a359-465ec01e42d7"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-fq"
      component={RelatorioQuinzenalFatorQualidadeAssociacao}
      isPrivate
      s="4d096d89-fadc-4029-bdef-8f7b6e35af3f"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-geral"
      component={RelatorioQuinzenalGeralAssociacao}
      isPrivate
      s="11a8ede4-8dd9-478b-9c0e-1f6330bff3a6"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-grafico"
      component={RelatorioQuinzenalGraficoAssociacao}
      isPrivate
      s="097302e8-87e5-4e49-9061-37d3cd80632f"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-sistemaatr"
      component={RelatorioQuinzenalSistemaatrAssociacao}
      isPrivate
      s="165011bd-b17a-43ab-a878-546138d3cfc2"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-sistemaatr-fq"
      component={RelatorioQuinzenalSistemaatrFatorQualidadeAssociacao}
      isPrivate
      s="412c1e1e-5ed0-4223-999d-03698cb59e37"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-sistemaatr-quq"
      component={RelatorioQuinzenalSistemaatrQuqAssociacao}
      isPrivate
      s="382dde55-5678-48f9-898f-a2007db06340"
    />
    <ProtectedRoute
      path="/relatorio-associacao-quinzenal-sistemaatr-atruq"
      component={RelatorioQuinzenalSistemaatrATRuqAssociacao}
      isPrivate
      s="cd2bacbb-6df8-4f2b-99bc-36a7b17d00f6"
    />
    <ProtectedRoute
      path="/relatorio-associacao-produtor-pagamento"
      component={RelatorioProdutorPagamentoAssociacao}
      isPrivate
      s="923806ad-b7bf-45b9-8cf4-b70f1d8e3136"
    />
    <ProtectedRoute
      path="/resumo-industria-associacao"
      component={ResumoIndustriaAssociacao}
      isPrivate
      s="50df0dab-18df-4e7a-ae29-ba472ddacd3c"
    />
    <ProtectedRoute
      path="/resumo-acumulado-associacao"
      component={ResumoAcumuladoAssociacao}
      isPrivate
      s="0c432893-aceb-4920-ab84-c20b04868e80"
    />
    <ProtectedRoute
      path="/resumo-tabela-entrega-associacao"
      component={ResumoTabelaEntregaAssociacao}
      isPrivate
      s="714e9e30-b6f6-42b1-a87b-eb6139d22cad"
    />
    <ProtectedRoute
      path="/resumo-tabela-entrega-industria"
      component={ResumoTabelaEntregaIndustria}
      isPrivate
      s="2abaa88e-f6de-47ca-8c64-1c06b0356817"
    />
    <ProtectedRoute
      path="/avaliacao-amostragem-dia-associacao"
      component={AvaliacaoAmostragemDiaAssociacao}
      isPrivate
      s="ac2080e9-e1a8-42b1-8740-363302d1a75b"
    />
    <ProtectedRoute
      path="/avaliacao-amostragem-safra-associacao"
      component={AvaliacaoAmostragemSafraAssociacao}
      isPrivate
      s="06169475-d868-4b21-adb0-0b4a2e3679f1"
    />
    <ProtectedRoute
      path="/avaliacao-clarificante-industria-associacao"
      component={AvaliacaoClarificanteIndustriaAssociacao}
      isPrivate
      s="957a0a0e-d32e-4fb7-9f6b-431c525c0eb2"
    />
    <ProtectedRoute
      path="/avaliacao-amostragem-intervalo-associacao"
      component={AvaliacaoAmostragemIntervaloAssociacao}
      isPrivate
      s="6698975c-0e7f-4123-91af-07c4455bc4f4"
    />
    <ProtectedRoute
      path="/avaliacao-amostragem-intervalo-carga-associacao"
      component={AvaliacaoAmostragemIntervaloCargaAssociacao}
      isPrivate
      s="7efe98e1-be62-402d-81bf-c2084c3d0fac"
    />
    <ProtectedRoute
      path="/estratificacao-cana-associacao"
      component={EstratificacaoCanaAssociacao}
      isPrivate
      s="51be05fa-bc5d-40dd-a39c-69056d8a8cd5"
    />
    <ProtectedRoute
      path="/estratificacao-atr-associacao"
      component={EstratificacaoATRAssociacao}
      isPrivate
      s="6bfeadd4-0a94-4958-bec9-030a328b263e"
    />
    <ProtectedRoute
      path="/estratificacao-fq-associacao"
      component={EstratificacaoFatorQualidadeAssociacao}
      isPrivate
      s="2ccac5d4-50f0-465b-9c78-1cf1de019826"
    />
    <ProtectedRoute
      path="/relatorio-taxa-associacao"
      component={RecolhimentoTaxaAssociacao}
      isPrivate
      s="cda6a134-7430-474f-8458-da9f5666a357"
    />
    <ProtectedRoute
      path="/relatorio-associado-associacao"
      component={AssociadoAssociacao}
      isPrivate
      s="cda6a134-7430-474f-8458-da9f5666a357"
    />
    <ProtectedRoute
      path="/relatorio-industria-viagem"
      component={RelatorioViagemIndustria}
      isPrivate
      s="40ca7f8e-cf50-4c6e-9ef7-a1d844135b5d"
    />
    <ProtectedRoute
      path="/relatorio-industria-diario"
      component={RelatorioDiarioIndustria}
      isPrivate
      s="0750b249-b106-42f7-a575-14c480ec4cfa"
    />
    <ProtectedRoute
      path="/relatorio-industria-diario-geral"
      component={RelatorioDiarioGeralIndustria}
      isPrivate
      s="dfdae672-2eab-4bd1-8b41-65cc84d5f79f"
    />
    <ProtectedRoute
      path="/relatorio-industria-diario-tipos"
      component={RelatorioDiarioTipoIndustria}
      isPrivate
      s="b221e8a0-5fd6-4e71-b9e8-1789793207a1"
    />
    <ProtectedRoute
      path="/relatorio-industria-diario-entrega"
      component={RelatorioDiarioEntregaIndustria}
      isPrivate
      s="24130fb2-96ec-458f-9dcb-69e8eb36c070"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal-sistemaatr-quq"
      component={RelatorioQuinzenalSistemaatrQuqIndustria}
      isPrivate
      s="6decd895-3316-4e98-899b-e4938d51ca81"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal-sistemaatr-atruq"
      component={RelatorioQuinzenalSistemaatrATRuqIndustria}
      isPrivate
      s="3200af37-41f5-47c0-8e96-0b425570ced9"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal-sistemaatr"
      component={RelatorioQuinzenalSistemaatrIndustria}
      isPrivate
      s="1ca4f39a-e501-44db-aa91-c4103197f3ad"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal"
      component={RelatorioQuinzenalIndustria}
      isPrivate
      s="6df6dd3a-435b-4f12-8151-36036012fedc"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal-fq"
      component={RelatorioQuinzenalFatorQualidadeIndustria}
      isPrivate
      s="56c557fa-b122-48ef-be1f-d66c626785a8"
    />
    <ProtectedRoute
      path="/relatorio-industria-quinzenal-grafico"
      component={RelatorioQuinzenalGraficoIndustria}
      isPrivate
      s="5092bb21-16ac-4bea-9abb-ba193b92c928"
    />
    <ProtectedRoute
      path="/industria-limite"
      component={IndustriaLimites}
      isPrivate
      s="13e05022-43ca-44f5-9a47-dafe19853491"
    />
    <ProtectedRoute
      path="/orplana-quinzenal-associacao"
      component={RelatorioQuinzenalOrplanaAssociacaoRelatq2}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/orplana-quinzenal-estado-relatq5"
      component={RelatorioQuinzenalOrplanaEstadoRelatq5}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/orplana-quinzenal-estado-relatq50"
      component={RelatorioQuinzenalOrplanaEstadoRelatq50}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/orplana-quinzenal-industria-relatq21"
      component={RelatorioQuinzenalOrplanaIndustriaRelatq21}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/orplana-quinzenal-industria-relatq28"
      component={RelatorioQuinzenalOrplanaIndustriaRelatq28}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/orplana-acumulado-associacao-relata2"
      component={RelatorioAcumuladoOrplanaAssociacaoRelata2}
      isPrivate
      s="55721f9e-71b5-4061-a80d-cbb4acf4cde5"
    />
    <ProtectedRoute
      path="/orplana-acumulado-regiao-relata42"
      component={RelatorioAcumuladoOrplanaRegiaoRelata42}
      isPrivate
      s="55721f9e-71b5-4061-a80d-cbb4acf4cde5"
    />
    <ProtectedRoute
      path="/orplana-acumulado-estado-relata46"
      component={RelatorioAcumuladoOrplanaEstadoRelata46}
      isPrivate
      s="55721f9e-71b5-4061-a80d-cbb4acf4cde5"
    />
    <ProtectedRoute
      path="/orplana-estrato-cana-relata44"
      component={RelatorioEstratificacaoOrplanaRelata44}
      isPrivate
      s="4ea55a5e-0d15-4d31-b3b1-ba5239287273"
    />
    <ProtectedRoute
      path="/orplana-safra-atualizacao"
      component={RelatorioSafraOrplanaAtualizacao}
      isPrivate
      s="0f2406cb-9108-46a9-9228-ed8397477614"
    />
    <ProtectedRoute
      path="/unica-quinzenal-estado-relatq5"
      component={RelatorioQuinzenalUnicaEstadoRelatq5}
      isPrivate
      s="98c7149d-10ef-4742-a33a-59bc0f6e060c"
    />
    <ProtectedRoute
      path="/unica-quinzenal-estado-relatq50"
      component={RelatorioQuinzenalUnicaEstadoRelatq50}
      isPrivate
      s="98c7149d-10ef-4742-a33a-59bc0f6e060c"
    />
    <ProtectedRoute
      path="/unica-quinzenal-detalhado"
      component={RelatorioQuinzenalUnicaDetalhado}
      isPrivate
      s="98c7149d-10ef-4742-a33a-59bc0f6e060c"
    />
    <ProtectedRoute
      path="/unica-mensal-detalhado"
      component={RelatorioMensalUnicaDetalhado}
      isPrivate
      s="cf628bb0-0cb5-4bf7-9462-89b96ffb2164"
    />
    <ProtectedRoute
      path="/unica-semanal-detalhado"
      component={RelatorioSemanalUnicaDetalhado}
      isPrivate
      s="c6a40083-5ca7-4a88-a7f3-b0f1c7f4d444"
    />
    <ProtectedRoute
      path="/grupo-permissao"
      component={GrupoPermissao}
      isPrivate
      s=""
    />
    <ProtectedRoute path="/usuario" component={Usuario} isPrivate s="" />
    <ProtectedRoute path="/perfil" component={Profile} isPrivate s="" />
    <ProtectedRoute
      path="/dash-industria"
      component={DashboardIndustria}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/dash-associacao"
      component={DashboardAssociacao}
      isPrivate
      s=""
    />
    <ProtectedRoute
      path="/dash-orplana"
      component={DashboardOrplana}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/dash-orplana-industrias"
      component={DashboardOrplanaUI}
      isPrivate
      s="63f8b346-cf17-468e-9d45-6e169c382a6a"
    />
    <ProtectedRoute
      path="/dash-unica"
      component={DashboardUnica}
      isPrivate
      s="c6a40083-5ca7-4a88-a7f3-b0f1c7f4d444"
    />
    <ProtectedRoute
      path="/dash-unica-industrias"
      component={DashboardUnicaUI}
      isPrivate
      s="c6a40083-5ca7-4a88-a7f3-b0f1c7f4d444"
    />
    <ProtectedRoute
      path="/dash-administracao"
      component={DashboardAdministracao}
      isPrivate
      s=""
    />
    <ProtectedRoute path="/home" component={Home} isPrivate s="" />
    <ProtectedRoute component={Home} isPrivate s="" />
  </Switch>
);

export default Routes;
