import React, { createContext, useState, useContext } from 'react';

interface IAction {
  action: string;
  title: string;
  icon?: string;
}

interface INotificationTemplate {
  title: string;
  body?: string;
  image?: string;
  icon?: string;
  lang?: string;
  vibrate?: number[];
  badge?: string;
  tag?: string;
  renotify?: boolean;
  actions?: IAction[];
}

interface INotificationContextData {
  requestPermission: () => void;
  getPermission: () => NotificationPermission;
  browserSupport: boolean;
  sendNotification: (notificacao: INotificationTemplate) => void;
}

const NotificationContext = createContext<INotificationContextData>(
  {} as INotificationContextData,
);

const NotificationProvider: React.FC = ({ children }) => {
  const [support] = useState(() => {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      return true;
    }
    return false;
  });

  const getPermission = (): NotificationPermission => {
    if (support) {
      return Notification.permission;
    }
    return 'denied';
  };

  const requestPermission = (): void => {
    if (support) {
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          const notificacao = {
            title: 'Notificação APP Sistema ATR',
            body: 'Foi habilitado com sucesso o serviço de notificação!',
          };
          sendNotification(notificacao);
        }
      });
    }
    return undefined;
  };

  const sendNotification = (notificacao: INotificationTemplate): void => {
    if (support && Notification.permission === 'granted') {
      const { title } = notificacao;
      const options = {
        body: notificacao.body,
        image: notificacao.image || undefined,
        icon: notificacao.icon || '/icon/favicon-96x96.png',
        lang: notificacao.lang || 'pt-BR',
        vibrate: notificacao.vibrate || [100, 50, 200],
        badge: notificacao.badge || '/icon/notify-icon-96x96.png',
        tag: notificacao.tag || undefined,
        renotify: notificacao.renotify || undefined,
        actions: notificacao.actions || undefined,
      };
      navigator.serviceWorker.ready.then(swreg => {
        swreg.showNotification(title, options);
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        browserSupport: support,
        getPermission,
        requestPermission,
        sendNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): INotificationContextData {
  const context = useContext(NotificationContext);

  return context;
}

export { NotificationProvider, useNotification };
