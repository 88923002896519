import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import { decrypt } from '../services/crypt';

interface IPermissions {
  id_sub_menu: string;
  servico: string;
  visivel: boolean;
}

interface IRegiaoOrplana {
  sigla?: string;
  descricao?: string;
}

interface IRegiaoUnica {
  sigla?: string;
  descricao?: string;
}

interface IEstado {
  sigla?: string;
  nome?: string;
}

interface IGrupoIndustria {
  razao_social: string;
  fantasia: string;
}

interface IDadosIndustria {
  id_industria: number;
  id_grupo_industria?: string;
  razao_social?: string;
  fantasia?: string;
  consecana?: string;
  id_regiao_orplana?: string;
  regiao_orplana?: IRegiaoOrplana;
  id_regiao_unica?: string;
  regiao_unica?: IRegiaoUnica;
  id_estado?: string;
  estado?: IEstado;
  grupo?: IGrupoIndustria;
}

interface IDadosAssociacao {
  id_associacao: number;
  razao_social?: string;
  fantasia?: string;
  sigla?: string;
  id_regiao_orplana?: string;
  regiao_orplana?: IRegiaoOrplana;
  id_regiao_unica?: string;
  regiao_unica?: IRegiaoUnica;
  id_estado?: string;
  estado?: IEstado;
  filiada_orplana: boolean;
}

interface IGetPermissions {
  permissoes: IPermissions[];
  isAdmin: boolean;
  modulo: string;
  id_safra?: string;
  safra?: number;
  vinculo?: string;
  complementos: {
    dados_industria?: IDadosIndustria;
    dados_associacao?: IDadosAssociacao;
  };
  isAssociacao?: boolean;
  isConsecana?: boolean;
  isIndustria?: boolean;
  isOrplana?: boolean;
  isProdutor?: boolean;
  isUnica?: boolean;
}

interface IVinculos {
  id_safra: string | undefined;
  safra: number | undefined;
  id_safra_ativa: string | undefined;
  safra_ativa: number | undefined;
  vinculo: string | undefined;
  isAssociacao: boolean | undefined;
  isConsecana: boolean | undefined;
  isIndustria: boolean | undefined;
  isOrplana: boolean | undefined;
  isProdutor: boolean | undefined;
  isUnica: boolean | undefined;
  complementos: {
    dados_industria?: IDadosIndustria;
    dados_associacao?: IDadosAssociacao;
  };
}

interface IPermissionContextData {
  permissions: IPermissions[];
  safraAtiva: number | undefined;
  idSafraAtiva: string | undefined;
  safra: number | undefined;
  idSafra: string | undefined;
  setSafra: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIdSafra: React.Dispatch<React.SetStateAction<string | undefined>>;
  vinculos: IVinculos;
  isAdmin: boolean;
  moduloName: string;
  getPermission(): Promise<void>;
  isAuthorized(id_sub_menu: string): boolean;
  resetPermissions(): void;
}

const PermissionContext = createContext<IPermissionContextData>(
  {} as IPermissionContextData,
);

const PermissionProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IPermissions[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [modulo, setModulo] = useState('');
  const [safra, setSafra] = useState<number | undefined>(undefined);
  const [idSafra, setIdSafra] = useState<string | undefined>(undefined);
  const [safraAtiva, setSafraAtiva] = useState<number | undefined>(undefined);
  const [idSafraAtiva, setIdSafraAtiva] =
    useState<string | undefined>(undefined);
  const [vinculos, setVinculos] = useState<IVinculos>({
    id_safra: undefined,
    safra: undefined,
    id_safra_ativa: undefined,
    safra_ativa: undefined,
    vinculo: undefined,
    isAssociacao: false,
    isConsecana: false,
    isIndustria: false,
    isOrplana: false,
    isProdutor: false,
    isUnica: false,
    complementos: {
      dados_industria: undefined,
      dados_associacao: undefined,
    },
  });

  const getPermission = useCallback(async () => {
    try {
      const response = await api.get(`permissoes`);
      const content = decrypt<IGetPermissions>(response.data);
      // console.log(content.permissoes);
      setData(content.permissoes);
      setIsAdmin(content.isAdmin);
      setModulo(content.modulo);
      setVinculos({
        id_safra: content.id_safra ? content.id_safra : undefined,
        safra: content.safra ? content.safra : undefined,
        id_safra_ativa: content.id_safra ? content.id_safra : undefined,
        safra_ativa: content.safra ? content.safra : undefined,
        vinculo: content.vinculo ? content.vinculo : undefined,
        isAssociacao: content.isAssociacao ? content.isAssociacao : false,
        isConsecana: content.isConsecana ? content.isConsecana : false,
        isIndustria: content.isIndustria ? content.isIndustria : false,
        isOrplana: content.isOrplana ? content.isOrplana : false,
        isProdutor: content.isProdutor ? content.isProdutor : false,
        isUnica: content.isUnica ? content.isUnica : false,
        complementos: content.complementos
          ? content.complementos
          : {
              dados_industria: undefined,
              dados_associacao: undefined,
            },
      });
      setSafra(content.safra ? content.safra : undefined);
      setIdSafra(content.id_safra ? content.id_safra : undefined);
      setSafraAtiva(content.safra ? content.safra : undefined);
      setIdSafraAtiva(content.id_safra ? content.id_safra : undefined);
    } catch {
      setData([]);
      setIsAdmin(false);
      setModulo('');
      setVinculos({
        id_safra: undefined,
        safra: undefined,
        id_safra_ativa: undefined,
        safra_ativa: undefined,
        vinculo: undefined,
        isAssociacao: false,
        isConsecana: false,
        isIndustria: false,
        isOrplana: false,
        isProdutor: false,
        isUnica: false,
        complementos: {
          dados_industria: undefined,
          dados_associacao: undefined,
        },
      });
      setSafra(undefined);
      setIdSafra(undefined);
    }
  }, []);

  const isAuthorized = useCallback(
    (id_sub_menu: string) => {
      if (!isAdmin) {
        const permitted = data.find(p => p.id_sub_menu === id_sub_menu);
        if (!permitted) {
          return false;
        }
        return true;
      }
      return true;
    },
    [data, isAdmin],
  );

  const resetPermissions = useCallback(() => {
    setData([]);
    setIsAdmin(false);
    setModulo('');
    setVinculos({
      id_safra: undefined,
      safra: undefined,
      id_safra_ativa: undefined,
      safra_ativa: undefined,
      vinculo: undefined,
      isAssociacao: false,
      isConsecana: false,
      isIndustria: false,
      isOrplana: false,
      isProdutor: false,
      isUnica: false,
      complementos: {
        dados_industria: undefined,
        dados_associacao: undefined,
      },
    });
    setSafra(undefined);
    setIdSafra(undefined);
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        permissions: data,
        getPermission,
        isAuthorized,
        resetPermissions,
        isAdmin,
        moduloName: modulo,
        vinculos,
        safra,
        idSafra,
        safraAtiva,
        idSafraAtiva,
        setSafra,
        setIdSafra,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

function usePermission(): IPermissionContextData {
  const context = useContext(PermissionContext);

  return context;
}

export { PermissionProvider, usePermission };
