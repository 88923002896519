import React from 'react';

import { PermissionProvider } from './permission';
import { LogProvider } from './log';
import { SocketProvider } from './socket';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ThemeAppProvider } from './theme';
import { NetworkProvider } from './network';
import { NotificationProvider } from './notification';

const AppProvider: React.FC = ({ children }) => (
  <ThemeAppProvider>
    <NetworkProvider>
      <NotificationProvider>
        <PermissionProvider>
          <LogProvider>
            <SocketProvider>
              <AuthProvider>
                <ToastProvider>{children}</ToastProvider>
              </AuthProvider>
            </SocketProvider>
          </LogProvider>
        </PermissionProvider>
      </NotificationProvider>
    </NetworkProvider>
  </ThemeAppProvider>
);

export default AppProvider;
