import React, { createContext, useCallback, useState, useContext } from 'react';
import { decode } from 'jsonwebtoken';
import { usePermission } from './permission';
import { useLog } from './log';
import { useSocket } from './socket';
import api from '../services/api';
import { decrypt, decryptString, encrypt } from '../services/crypt';

interface IUser {
  id: string;
  nome: string;
  email: string;
  login: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface ISignInCredentials {
  login: string;
  senha: string;
}

interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
  checkToken(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { conectarSocket, reconectarSocket, desconectarSocket } = useSocket();
  const { getPermission, resetPermissions } = usePermission();
  const { setLogAcesso } = useLog();

  const [data, setData] = useState<IAuthState>(() => {
    const tokenStorage = localStorage.getItem('@SistemaATR:token');
    const userStorage = localStorage.getItem('@SistemaATR:user');
    if (tokenStorage && userStorage) {
      const token = decryptString(tokenStorage);
      const user = decryptString(userStorage);
      let isExpired = false;
      const decodedToken = decode(token, { complete: true });
      const dateNow = new Date();
      if (
        !decodedToken ||
        (decodedToken &&
          decodedToken.payload.exp <
            Number(dateNow.getTime().toString().substring(0, 10)))
      )
        isExpired = true;

      if (!isExpired) {
        api.defaults.headers.authorization = encrypt(`Bearer ${token}`);
        getPermission();
        const usuario = JSON.parse(user);
        setTimeout(() => reconectarSocket(usuario.id), 1000);
        return { token, user: JSON.parse(user) };
      }
      localStorage.removeItem('@SistemaATR:token');
      localStorage.removeItem('@SistemaATR:user');
      desconectarSocket();
      return {} as IAuthState;
    }

    return {} as IAuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@SistemaATR:token');
    localStorage.removeItem('@SistemaATR:user');

    resetPermissions();
    desconectarSocket();
    setData({} as IAuthState);
  }, [resetPermissions, desconectarSocket]);

  const checkToken = useCallback(() => {
    const token = data ? data.token : undefined;
    if (token) {
      let isExpired = false;
      const decodedToken = decode(token, { complete: true });
      const dateNow = new Date();
      if (
        !decodedToken ||
        (decodedToken &&
          decodedToken.payload.exp <
            Number(dateNow.getTime().toString().substring(0, 10)))
      )
        isExpired = true;

      if (isExpired) {
        localStorage.removeItem('@SistemaATR:token');
        localStorage.removeItem('@SistemaATR:user');

        resetPermissions();
        setData({} as IAuthState);
      }
    }
  }, [resetPermissions, data]);

  const signIn = useCallback(
    async ({ login, senha }) => {
      const response = await api.post('sessoes', {
        payload: encrypt(
          JSON.stringify({
            login,
            senha,
          }),
        ),
      });

      const content = decrypt<IAuthState>(response.data);

      const { token, user } = content;

      const tokenStorage = encrypt(token);
      const userStorage = encrypt(JSON.stringify(user));

      localStorage.setItem('@SistemaATR:token', tokenStorage);
      localStorage.setItem('@SistemaATR:user', userStorage);

      api.defaults.headers.authorization = encrypt(`Bearer ${token}`);
      getPermission();

      setData({ token, user });

      setLogAcesso();

      setTimeout(() => conectarSocket(user.id), 1000);
    },
    [getPermission, setLogAcesso, conectarSocket],
  );

  const updateUser = useCallback(
    (user: IUser) => {
      const userStorage = encrypt(JSON.stringify(user));
      localStorage.setItem('@SistemaATR:user', userStorage);

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        checkToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
