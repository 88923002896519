import React, { useRef, useCallback, useEffect } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { usePermission } from '../../hooks/permission';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo_sistemaatr3.png';
import logoImgConsecana from '../../assets/logo_consecana.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';

interface ISignInFormData {
  login: string;
  senha: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { vinculos, isAdmin } = usePermission();
  const { signIn, user } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (vinculos.isIndustria) {
        history.push('/dash-industria');
      } else if (vinculos.isAssociacao) {
        history.push('/dash-associacao');
      } else if (vinculos.isOrplana) {
        history.push('/dash-orplana');
      } else if (isAdmin) {
        history.push('/dash-administracao');
      }
    }
  }, [user, vinculos, history, isAdmin]);

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          login: Yup.string().required('E-mail obrigatório'),
          senha: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          login: data.login,
          senha: data.senha,
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Container>
      <Background />

      <Content>
        <AnimationContainer>
          <img src={logoImg} height="150px" alt="Sistema ATR" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu logon</h1>

            <Input name="login" icon={FiMail} placeholder="Login" />
            <Input
              name="senha"
              icon={FiLock}
              type="password"
              placeholder="Senha"
            />

            <Button type="submit">Entrar</Button>

            <Link to="/esqueci-senha">Esqueci minha senha</Link>
          </Form>
          <img src={logoImgConsecana} height="80px" alt="Sistema ATR" />
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
