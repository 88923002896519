/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import * as React from 'react';

// Agument the Theme interface
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    appDrawer: {
      width: React.CSSProperties['width'];
      breakpoint: Breakpoint;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    appDrawer?: {
      width?: React.CSSProperties['width'];
      breakpoint?: Breakpoint;
    };
  }
}

// Augument the Palette interface
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    tertiary: {
      main: string;
    };
    validated: {
      main: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface PaletteOptions {
    tertiary?: {
      main?: string;
    };
    validated?: {
      main?: string;
    };
  }
}
function customTheme(options: ThemeOptions) {
  return createMuiTheme({
    appDrawer: {
      width: 320,
      breakpoint: 'lg',
    },
    palette: {
      primary: {
        light: '#64b89f',
        main: '#2e9677',
        dark: '#1a5c1c',
        // contrastText: '#fff',
      },
      secondary: {
        light: '#f8324526',
        main: '#f83245',
        dark: '#a90000',
        // contrastText: '#aca5a5',
      },
      background: {
        // paper: '#f5f2e9',
        default: '#f5f2e9',
      },
      text: {
        primary: '#000',
      },
      tertiary: {
        main: '#240292',
      },
      validated: {
        main: '#1f5e03',
      },
    },
    ...options,
  });
}

const lightTheme = customTheme({
  overrides: {
    MuiTextField: {
      root: {
        '& .Mui-error': {
          color: '#ff5e50',
        },
        '& .MuiInputBase-input': {
          color: '#000000',
        },
        '& .Mui-focused': {
          color: '#000000',
        },
      },
    },
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
    MuiInputLabel: {
      root: {
        '& .Mui-focused': {
          color: '#000000',
        },
      },
      filled: {
        '& .Mui-focused': {
          color: '#000000',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#000000 !important',
        },
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.3)',
        },
      },
    },
    MuiCheckbox: {
      root: {
        '& .Mui-checked': {
          color: 'rgba(0, 0, 0, 0.54) !important',
        },
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

const darktheme = customTheme({
  palette: {
    primary: {
      light: '#323232',
      main: '#999898',
      dark: '#121212',
      contrastText: '#fff',
    },
    background: {
      paper: '#323232',
      default: '#222222',
    },
    text: {
      primary: '#fff',
    },
    tertiary: {
      main: '#0080ff',
    },
    validated: {
      main: '#66ea2c',
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        '& .MuiInputLabel-root': {
          color: '#999898',
        },
        '& .Mui-error': {
          color: lightTheme.palette.secondary.light,
        },
        '& .MuiInputBase-input': {
          color: '#eee',
        },
        '& .Mui-focused': {
          color: '#eee',
        },
        '& .MuiInput-input::placeholder': {
          color: '#999898',
        },
      },
    },
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)',
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.3)',
        },
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

export { lightTheme, darktheme };
